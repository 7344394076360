<template>
  <span ref="tooltip" :title="info">
    <span v-if="info" style="width: 15px; height: 15px;" class="position-relative justify-content-center align-items-center d-inline-flex text-white rounded-1">
      <Icon v-if="!icon" icon="material-symbols-light:help-outline" class="text-primary" />
      <Icon v-else :icon="icon" class="text-primary" />
    </span>
  </span>
</template>

<script setup>
defineProps([ "info", "icon" ]);
const { $bootstrap } = useNuxtApp();
const tooltip = ref({});

onMounted(() => {
  // eslint-disable-next-line no-new
  new $bootstrap.Tooltip(tooltip.value);
});
</script>
